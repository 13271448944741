import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "波若波罗蜜多心经"
    }}>{`波若波罗蜜多心经`}</h1>
    <p>{`
`}<br parentName="p"></br>{`
`}{``}</p>
    <p>{`　　guān zì zài pú sà , xíng shēn bō rě bō luó mì duō shí，zhào jiàn wǔ yùn jiē kōng, dù yī qiē kǔ è。`}</p>
    <p>{`　　观自在菩萨，行深般若波罗蜜多时，照见五蕴皆空，度一切苦厄。`}</p>
    <p>{`　　shè lì zǐ, sè bù yì kōng ，kōng bù yì sè , sè jí shì kōng , kōng jí shì sè。`}</p>
    <p>{`　　舍利子，色不异空，空不异色，色即是空，空即是色。`}</p>
    <p>{`　　shòu xiǎng xíng shí，yì fù rú shì。`}</p>
    <p>{`　　受想行识，亦复如是。`}</p>
    <p>{`　　shè lì zǐ, shì zhū fǎ kōng xiāng,`}</p>
    <p>{`　　舍利子，是诸法空相，`}</p>
    <p>{`　　bù shēng bù miè, bù gòu bù jìng, bù zēng bù jiǎn ,`}</p>
    <p>{`　　不生不灭，不垢不净，不增不减，`}</p>
    <p>{`　　shì gù kōng zhōng wú sè, wú shòu xiǎng xíng shí,`}</p>
    <p>{`　　是故空中无色，无受想行识，`}</p>
    <p>{`　　wú yǎn ěr bí shé shēn yì, wú sè shēng xiāng wèi chù fǎ, wú yǎn jiè,`}</p>
    <p>{`　　无眼耳鼻舌身意，无色声香味触法，无眼界，`}</p>
    <p>{`　　nǎi zhì wú yì shí jiè, wú wú míng , yì wú wú míng jìn,`}</p>
    <p>{`　　乃至无意识界，无无明，亦无无明尽，`}</p>
    <p>{`　　nǎi zhì wú lǎo sǐ, yì wú lǎo sǐ jìn。`}</p>
    <p>{`　　乃至无老死，亦无老死尽。`}</p>
    <p>{`　　wú kǔ jí miè dào, wú zhì yì wú dé, yǐ wú suǒ dé gù。`}</p>
    <p>{`　　无苦集灭道，无智亦无得，以无所得故。`}</p>
    <p>{`　　pú tí sà duǒ yī bō rě bō luó mì duō gù xīn wú guà ài。`}</p>
    <p>{`　　菩提萨埵，依般若波罗蜜多故，心无挂碍。`}</p>
    <p>{`　　wú guà ài gù, wú yǒu kǒng bù, yuǎn lí diān dǎo mèng xiǎng, jiū jìng niè pán。`}</p>
    <p>{`　　无挂碍故，无有恐怖，远离颠倒梦想，究竟涅槃。`}</p>
    <p>{`　　sān shì zhū fó, yī bō rě bō luó mì duō gù, dé ā nuò duō luó sān miǎo sān pú tí。`}</p>
    <p>{`　　三世诸佛，依般若波罗蜜多故，得阿耨多罗三藐三菩提。`}</p>
    <p>{`　　gù zhī bō rě bō luó mì duō, shì dà shén zhòu, shì dà míng zhòu,`}</p>
    <p>{`　　故知般若波罗蜜多，是大神咒，是大明咒，`}</p>
    <p>{`　　shì wú shàng zhòu, shì wú děng děng zhòu。néng chú yī qiē kǔ, zhēn shí bù xū。`}</p>
    <p>{`　　是无上咒，是无等等咒。能除一切苦，真实不虚。`}</p>
    <p>{`　　gù shuō bō rě bō luó mì duō zhòu。`}</p>
    <p>{`　　故说般若波罗蜜多咒。`}</p>
    <p>{`　　jí shuō zhòu yuē：`}</p>
    <p>{`　　即说咒曰：`}</p>
    <p>{`　　jiē dì jiē dì, bō luó jiē dì, bō luó sēng jiē dì, pú tí sà pó hē。`}</p>
    <p>{`　　揭谛揭谛，波罗揭谛，波罗僧揭谛，菩提萨婆诃。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      